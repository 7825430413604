<template>
  <div>
    <el-form
      ref="queryForm"
      :model="queryForm"
      :loading="tableLoading"
      :rules="rules"
      inline
    >
      <el-form-item label="采购订单号">
        <el-input v-model="queryForm.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" disabled />
      </el-form-item>
      <el-form-item label="Style">
        <Select
          v-model="queryForm.style"
          api-key="styleList"
          disabled
          :configuration=" { key: 'id', label: 'styleName', value: 'styleName' }"
        />
      </el-form-item>
      <el-form-item label="Color">
        <Select
          v-model="queryForm.colorList"
          :select-options="colorOptions"
          :configuration="{ key: 'label', label: 'label', value: 'label' }"
          clearable
          @change="queryForm.sizeList=[],sizeOptions=[]"
        />
      </el-form-item>
      <el-form-item label="Size">
        <el-select v-model="queryForm.sizeList" multiple clearable filterable @visible-change="handleSizeOptions($event,queryForm.colorList)">
          <el-option
            v-for="item in sizeOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="handleQuery()">{{ $t('page.search') }}</el-button>
      <el-button @click="handleQueryFormReset()">{{ $t('page.reset') }}</el-button>
      <br>
      <el-form-item label="调价原因" prop="changePriceReason">
        <el-input v-model="queryForm.changePriceReason" type="textarea" maxlength="300" show-word-limit />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="queryForm.remark" type="textarea" maxlength="300" show-word-limit />
      </el-form-item>
    </el-form>

    <vxe-table
      ref="multipleTable"
      class="mt-4"
      max-height="500px"
      align="center"
      :data="queryForm.changePriceBillOrderDetailSaveDtoList"
      :loading="tableLoading"
      :row-config="{ height: 80 }"
      row-id="deleteKey"
      :checkbox-config="{ reserve: true}"
      @checkbox-all="({records})=>{multipleSelection=records}"
      @checkbox-change="({records})=>{multipleSelection=records}"
    >

      <vxe-table-column type="checkbox" width="80" />
      <vxe-table-column type="index" width="80" title="序号" />
      <vxe-table-column field="style" title="Style" />
      <vxe-table-column field="color" title="Color" />
      <vxe-table-column field="size" title="Size" />
    </vxe-table>
    <el-row class="grid-content bg-blue mt-4" type="flex" justify="center">
      <el-button type="primary" :disabled="!multipleSelection.length" @click="handleSubmit">保存</el-button>
    </el-row>
  </div>
</template>

<script>
import Select from '@/components/Selection'
import { getCanChangePricePurchaseOrderInfo, getPoColorSizeMap } from '@/api/adjust-price'
import { omit } from 'lodash'
import { FloatSub } from '@/utils/acc'
export default {
  components: { Select },

  data() {
    return {
      multipleSelection: [],
      queryForm: {
        changePriceBillOrderDetailSaveDtoList: [],
        colorList: '',
        sizeList: [],
        style: '',
        remark: '',
        changePriceReason: '',
        currencyType: ''
      },
      colorOptions: [],
      sizeOptions: [],
      tableLoading: false,
      rules: {
        changePriceReason: [
          { required: true, message: '必填', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    queryParams() {
      const { colorList, purchaseOrderCode, sizeList, style, changePriceBillCode } = this.queryForm
      return { colorList: colorList ? [colorList] : [], purchaseOrderCode, sizeList, style, changePriceBillCode }
    }
  },
  mounted() {
    const { style, purchaseOrderCode, changePriceBillCode } = this.$route.query
    Object.assign(this.queryForm, { style, purchaseOrderCode, changePriceBillCode })
    this.handleQuery(true)
    this._getPoColorSizeMap(purchaseOrderCode)
  },
  methods: {
    handleCheckboxChange({ records }) {
      this.multipleSelection = records
    },
    handleSizeOptions(visible, color) {
      if (visible) {
        if (color) {
          this.sizeOptions = this.colorOptions.find(item => item.label === color)?.value
        } else {
          this.$message.warning('请先选择Style')
        }
      }
    },
    async _getPoColorSizeMap(purchaseOrderCode) {
      const { datas } = await getPoColorSizeMap({ purchaseOrderCode })
      this.colorOptions = Object.keys(datas).map(item => { return { label: item, value: datas[item] } })
    },
    async handleQuery(tag) {
      try {
        this.tableLoading = true
        const { datas } = await getCanChangePricePurchaseOrderInfo(this.queryParams)
        datas.changePriceBillOrderDetailSaveDtoList = datas.changePricePurchaseOrderDetailDtoList &&
        datas.changePricePurchaseOrderDetailDtoList.map(e => { return { ...e, deleteKey: `${this.$route.query.purchaseOrderCode}${e.style}${e.color}${e.size}`, purchaseOrderCode: `${this.$route.query.purchaseOrderCode}` } })
        // 默认草稿
        const currentAdjustData = JSON.parse(sessionStorage.getItem('currentAdjustData'))
        const obj = currentAdjustData && currentAdjustData.changePriceBillOrderList.find(item => item.purchaseOrderCode === this.$route.query.purchaseOrderCode)
        Object.assign(this.queryForm, datas, { billStatus: currentAdjustData.billStatus || '1' })
        if (obj) {
          // 默认带出调价原因
          this.$set(this.queryForm, 'changePriceReason', obj.changePriceReason)
          this.$set(this.queryForm, 'remark', obj.remark)
          this.queryForm.changePriceBillOrderDetailSaveDtoList.map(item => {
            const currentObj = obj.changePriceBillOrderDetailSaveDtoList.find(e => e.style === item.style && e.color === item.color && e.size === item.size)
            // 第一次进页面 添加同一po明细&&默认勾选
            currentObj && tag && this.$refs.multipleTable.toggleCheckboxRow(item, true) && this.multipleSelection.push(item)
          })
        }
      } finally {
        this.tableLoading = false
      }
    },
    handleQueryFormReset() {
      this.queryForm.colorList = ''
      this.queryForm.sizeList = []
      // this.$refs.multipleTable.clearCheckboxRow()
      this.handleQuery()
    },
    handleSubmit() {
      this.$refs.queryForm.validate(valid => {
        if (valid) {
          // 在缓存中同步调整后单价字段
          const currentAdjustData = JSON.parse(sessionStorage.getItem('currentAdjustData'))
          const obj = currentAdjustData && currentAdjustData.changePriceBillOrderList.find(item => item.purchaseOrderCode === this.$route.query.purchaseOrderCode)
          if (obj) {
            // 选择了原明细需保留原数据，重新计算幅度
            this.multipleSelection.map(item => {
              const currentObj = obj.changePriceBillOrderDetailSaveDtoList.find(e => e.style === item.style && e.color === item.color && e.size === item.size)
              currentObj && this.$set(item, 'changeAfterHaveTaxPrice', currentObj.changeAfterHaveTaxPrice)
              currentObj && this.$set(item, 'changeRange', FloatSub(currentObj.changeAfterHaveTaxPrice, currentObj.changeBeforeHaveTaxPrice))
            })
            Object.assign(obj, this.queryForm, { changePriceBillOrderDetailSaveDtoList: this.multipleSelection })
          } else {
            currentAdjustData.changePriceBillOrderList.push(Object.assign(this.queryForm, { changePriceBillOrderDetailSaveDtoList: this.multipleSelection }))
          }
          Object.assign(currentAdjustData, this.queryForm)
          sessionStorage.setItem('currentAdjustData', JSON.stringify(omit(currentAdjustData, ['rules', 'changePricePurchaseOrderDetailDtoList'])))
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style></style>
